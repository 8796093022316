<template>
  <div id="roster">
    <b-card>
      <filter-component class="m-2" @filter="filter" />
      <published-roster-component ref="uploaded-roster" :filterData="filterData" v-if="filterData.view_as && filterData.view_as.name === 'Employee List'" />
      <sister-company-roster-component ref="sister-company-roster" :filterData="filterData" v-else />
    </b-card>
  </div>
</template>
<script>
import { BCard } from "bootstrap-vue";
import FilterComponent from "./components/FilterComponent.vue";
import PublishedRosterComponent from "./components/PublishedRosterComponent.vue";
import SisterCompanyRosterComponent from './components/SisterCompanyRosterComponent.vue';

export default {
  components: {
    BCard,
    PublishedRosterComponent,
    SisterCompanyRosterComponent,
    FilterComponent,
  },
  data() {
    return {
      filterData: {
        role: "admin",
        company: null,
        customer: null,
        sector: null,
        user_id: null,
        customer_id: null,
        sector_id: null,
        status: 1,
      },
    };
  },
  methods: {
    filter(query) {
      this.filterData = query;
      this.$refs['uploaded-roster']?.reCall();
      this.$refs['sister-company-roster']?.reCall();
    },
  },
};
</script>
<style scoped>
.title {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 43px;
  color: #515b60;
  margin-left: 20px;
}
.row {
  margin-right: 10px;
}
</style>
